<template>
  <div>
    <el-row>
      <el-col>
        <el-result icon="success" :title="$t('OrderGoodsSuccess')">
          <template slot="extra">
            <!--继续购物-->
            <el-button type="primary" size="medium" @click="byGoods">{{$t('button.continue.shopping')}}</el-button>
            <!--查看订单-->
            <el-button size="medium" @click="goOrderList">{{$t('paypal.viewOrder')}}</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'PayReturnPage',
  methods: {
    byGoods() {
      this.$router.push({path: '/'})
    },
    goOrderList() {
      this.$router.push({path: '/order-list/index'})
    }
  }
}
</script>

<style>

</style>
